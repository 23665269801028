import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const JobsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Jobs.mov"
      videoPosterUrl="/intros/Intro%20-%20Jobs.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.title"
            defaultMessage="Add your jobs"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text1"
            defaultMessage="Start your recruitment process with adding new jobs to the product. You can use bulk file import or adding them to the system."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text2"
            defaultMessage="Every job has information about requisition (hiring team, team, position and recruiter) and job content that will be published on the job boards and careers website."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.jobs.intro.text3"
            defaultMessage="Check and configure integrations with job boards, careers website and preview your job postings. You can publish them right away or keep them unpublished and activate any time you want to advertise the opening. "
          />
        </p>
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.jobs.intro.tips.title"
            defaultMessage="What are jobs?"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.title"
                defaultMessage="Requisitions"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.requisitions.text"
                defaultMessage="Each job is made up of two main parts. First, there are requisitions. Think of them as essential headcount planning tool highlighting how many new faces you need within a specific team, with a desired position and seniority. They are necessary to hire a candidate."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.title"
                defaultMessage="Job postings"
              />
            ),
            icon: 'Megaphone',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.postings.text"
                defaultMessage="Then, there’s the job posting. This is the job ad you will share to attract people, spotlighting the essence of a position and your company - usual responsibilities, required skills, and the unique culture of your organisation. You can share them internally among your team members or share out to the world on your dedicated careers website and selected job boards. Let everyone know that you’re on the lookout for new talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.title"
                defaultMessage="Configuring job boards"
              />
            ),
            icon: 'IndustrialGear',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.board.text"
                defaultMessage="Check and configure integrations with job boards and careers website and preview your job postings. You can publish them right away or keep them unpublished and activate any time you want to advertise the opening. "
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.jobs.title"
                defaultMessage="Add your jobs"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.jobs.intro.tips.jobs.text"
                defaultMessage="When you are ready to grow your organisation, the first step is to add jobs. You can create them one by one or save time by uploading a bunch all at once."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
