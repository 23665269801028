import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  TableRequestInterface,
} from '../interfaces'
import {
  AllDocumentsStats,
  DocumentGenerateInterface,
  DocumentInterface,
  DocumentRequestInterface,
  DocumentsBulkRequestInterface,
  DocumentsCategoryInterface,
  DocumentUploadRequestInterface,
  DocusignDocumentInterface,
  EmbeddedDocumentInterface,
  EmployeeDocumentInterface,
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface,
  DocumentsBulkRequestEligibilityGroupInterface,
  TemplateSqlFieldDataType,
} from '@src/interfaces/documents'
import { saveFileByBlob } from '@src/utils'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { OptionInterface } from '@src/interfaces/selectors'
import { useFetch } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { DocumentsTemplateDataFieldRecipientId } from '@src/interfaces/documentsTemplates'
import { getCommentsAPI } from './comments'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'

export const newDocumentRequests: RequestInterfaceNew<DocumentUploadRequestInterface> = {
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`),
  submit: async data => {
    const { file, ...rest } = data
    const formData = new FormData()
    if (file) {
      formData.append('file', file, file.name)
    }
    const result = await apiWithoutHandling.post(
      `${API.EMPLOYEES}/${data.employee?.id}${API.DOCUMENTS}/uploads`,
      rest,
    )
    return api
      .post(`${API.DOCUMENTS}/uploads/${result.data.id}/fileForm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment`,
        },
      })
      .catch(e => {
        api.delete(
          `${API.EMPLOYEES}/${data.employee?.id}${API.DOCUMENTS}/uploads/${result.data.id}`,
        )
        throw e
      })
  },
  update: async (data, { id, employeeId }) => {
    const { file, ...rest } = data
    if (file) {
      const formData = new FormData()
      if (file) {
        formData.append('file', file, file.name)
      }
      await apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`,
        rest,
      )
      return api.post(`${API.DOCUMENTS}/uploads/${id}/fileForm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment`,
        },
      })
    }
    return apiWithoutHandling.patch(
      `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`,
      rest,
    )
  },
  delete: async ({ id }, { employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`),
}

export const useGetUploadedDocument = (
  employeeId: number,
  documentId?: number | string,
) =>
  useFetch<DocumentUploadRequestInterface>(
    documentId
      ? `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${documentId}`
      : null,
  )

export const deleteDocumentUpload = (id: string, employeeId: number) =>
  apiWithoutHandling.delete(
    `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`,
  )

export const deleteDocumentUploadFile = (id: number) =>
  api.delete(`${API.DOCUMENTS}/uploads/${id}/file`)

export const requestDocumentRequest: RequestInterfaceNew<DocumentInterface> = {
  get: newDocumentRequests.get,
  update: newDocumentRequests.update,
  submit: async data =>
    apiWithoutHandling.post(
      `${API.EMPLOYEES}/${data.employee?.id}/requestDocumentUpload`,
      data,
    ),
  delete: async ({ id }) => api.delete(`${API.DOCUMENTS}/uploads/${id}`),
}

export const requestDocumentByTemplateRequests: RequestInterfaceNew<DocumentRequestInterface> =
  {
    get: async ({ id, employeeId }) =>
      api.get(`${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS_REQUEST_BY_TEMPLATE}/${id}`),
    update: async (data, { id, employeeId }) =>
      api.patch(
        `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS_REQUEST_BY_TEMPLATE}/${id}`,
        data,
      ),
    submit: async (data, { employeeId }) =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS_REQUEST_BY_TEMPLATE}`,
        data,
      ),
    delete: async (_, { id, employeeId }) =>
      api.delete(
        `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS_REQUEST_BY_TEMPLATE}/${id}`,
      ),
  }

export const requestDocumentByCollection = (
  collectionId: string | number,
  data: { employee: { id: string } },
) => apiWithoutHandling.post(`${API.DOCUMENTS}/collections/${collectionId}/request`, data)

export const requestDocumentBySelfServe = (templateId: string | number) =>
  apiWithoutHandling.post(`${API.DOCUMENTS}/templates/${templateId}/selfServe`)

export const generateDocumentRequests: RequestInterfaceNew<DocumentGenerateInterface> = {
  get: async ({ id, employeeId }) =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/generate_document/${id}`,
    ),
  update: async (data, { id, employeeId }) =>
    api.patch(
      `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/generate_document/${id}`,
      data,
    ),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(
      `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/generate_document`,
      data,
    ),
  delete: async (_, { id, employeeId }) =>
    api.delete(
      `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/generate_document/${id}`,
    ),
}

export const getEmployeeDocumentNames = (
  requestData: FetchDataQueryInterface,
  employeeId?: number,
): AxiosPromise<{ options: OptionInterface[] }> => {
  const { sortBy, filters, page } = requestData
  return api.get(`${API.EMPLOYEES}/${employeeId}/uploadedDocumentNames`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const downloadUploadedAsBlob = async (id: number | string) => {
  const response = await api.get<Blob>(`${API.DOCUMENTS}/uploads/${id}/file`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(response.data)
}

export const downloadUploaded = async (
  id: number | string,
  employeeId: number | string,
) => {
  const response = await api.get(`${API.DOCUMENTS}/uploads/${id}/file`, {
    responseType: 'arraybuffer',
  })
  const infoResponse = await api.get<DocumentInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}`,
  )
  saveFileByBlob(response.data as BinaryType, infoResponse?.data.file_name)
}

export const downloadDocusignEmbedded = async (id: number | string) => {
  const response = await api.get<BinaryType>(
    `${API.DOCUMENTS}/docuSign/envelopes/${id}/files`,
    {
      responseType: 'arraybuffer',
    },
  )
  saveFileByBlob(response.data, `document-${id}.zip`)
}

export const getEmbeddedInfo = (id: string) =>
  api.get<EmbeddedDocumentInterface>(`${API.DOCUMENTS}/sentEnvelopeRecipients/${id}`)

export const useGetEmbeddedDocumentInfo = (id: string | number | undefined) =>
  useFetch<EmbeddedDocumentInterface>(
    id ? `${API.DOCUMENTS}/sentEnvelopeRecipients/${id}` : null,
  )

export const getDocusignInfo = (id: string) =>
  api.get<DocusignDocumentInterface>(`${API.DOCUMENTS}/docuSign/envelopeRecipients/${id}`)

export const useGetDocusignInfo = (id: string | number | undefined) =>
  useFetch<DocusignDocumentInterface>(
    id ? `${API.DOCUMENTS}/docuSign/envelopeRecipients/${id}` : null,
  )

export const signEmbedded = (id: number | string, params?: object) => {
  return api
    .get<{ url: string }>(
      `${API.DOCUMENTS}/sentEnvelopeRecipients/${id}/sign`,
      params
        ? {
            params,
          }
        : undefined,
    )
    .then(response => {
      if (response?.data?.url) {
        window.open(response?.data?.url, '__blank')
      }
    })
}

export const approveDocumentUpload = (employeeId: string, id: number) =>
  api.post(`${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}/approve`)

export const rejectDocumentUpload = (
  employeeId: string,
  id: number,
  data?: { rejection_comment: string },
) => api.post(`${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}/reject`, data)

export const useDocumentsRequestApprovals = (
  employeeId: string,
  id: number,
  skip: boolean = false,
) =>
  useFetch<ApprovalFlowResponse>(
    skip
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${id}/approvals`,
  )

export const getAllDocuments = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeDocumentInterface>
> =>
  api.get(`${API.EMPLOYEES}/documents`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getAllDocumentsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<AllDocumentsStats>(`${API.EMPLOYEES}/documents/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getDocumentsCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `${API.DOCUMENTS}/uploads/${id}/comments`,
  })

export const useGetEmployeeDocumentsCategories = (
  employeeId: number | string | undefined,
) => {
  return useFetch<{ options: DocumentsCategoryInterface[] }>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/documentCategories` : null,
  )
}

export const documentsBulkRequestFormRequests: RequestInterfaceNew<DocumentsBulkRequestInterface> =
  {
    get: async ({ id }) => api.get(`${API.DOCUMENTS}/bulkRequest/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUMENTS}/bulkRequest/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.DOCUMENTS}/bulkRequest`, data),
    delete: async ({ id }) => api.delete(`${API.DOCUMENTS}/bulkRequest/${id}`),
  }

export const attachDocumentsBulkRequestEligibilityGroup = (
  requestId: string | number,
  data?: DocumentsBulkRequestEligibilityGroupInterface,
) => apiWithoutHandling.post(`${API.DOCUMENTS}/bulkRequest/${requestId}/employees`, data)

export const documentsBulkRequestActivate = (id: string | number) =>
  api.post(`${API.DOCUMENTS}/bulkRequest/${id}/submit`)

export const documentsBulkRequestTriggerValidation = (id: string | number) =>
  api.post(`${API.DOCUMENTS}/bulkRequest/${id}/employees/validate`)

export const documentsBulkRequestsTableRequests: TableRequestInterface<DocumentsBulkRequestInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`${API.DOCUMENTS}/bulkRequest`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const getDocumentsBulkRequestEligibleEmployeesTableRequests = (
  id: string | number,
): TableRequestInterface<
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.DOCUMENTS}/bulkRequest/${id}/employees`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(`${API.DOCUMENTS}/bulkRequest/${id}/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const documentsBulkRequestUnassignEmployee = (
  requestId: string | number,
  employeesIds: number[],
  invertSelection: boolean,
) =>
  api.post(`${API.DOCUMENTS}/bulkRequest/${requestId}/employees/unassign`, {
    employees_filters: {
      id: employeesIds,
    },
    negate_filters: invertSelection,
  })

export const useGetTemplateFieldSqlSourcesOptions = (
  enabled: boolean,
  signerRole: DocumentsTemplateDataFieldRecipientId,
  dataType: TemplateSqlFieldDataType,
) =>
  useFetch<GetRequestInterface<IdAndName>>(
    enabled ? `/documents/templates/sqlSources` : null,
    undefined,
    {
      params: {
        fields: 'id,name',
        signer_role: signerRole,
        data_type: dataType,
      },
    },
  )

export const updateDocumentUploadInfo = (
  employeeId: string | number,
  documentId: string | number,
  data: DocumentUploadRequestInterface,
) =>
  apiWithoutHandling.patch(
    `${API.EMPLOYEES}/${employeeId}${API.DOCUMENTS}/uploads/${documentId}`,
    data,
  )

export const updateDocumentBulkSessionUploadInfo = (
  sessionId: number,
  rowId: number,
  data: Partial<ImportDocumentsItemInterface>,
) =>
  apiWithoutHandling.patch(
    `${API.DOCUMENTS_UPLOADS_BULK}/${sessionId}/items/${rowId}`,
    data,
  )
