import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const CandidatesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Candidates.mov"
      videoPosterUrl="/intros/Intro%20-%20Candidates.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.title"
            defaultMessage="Add candidates"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text1"
            defaultMessage="When hundreds of candidates apply for a role, having all the information about them is critical in maintain a high level of hiring accuracy. Add your candidates to the system. You have the option to upload candidates in bulk during the onboarding or add them individually later."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text2"
            defaultMessage="All of the essential candidate data is consolidated on the candidate profile, enabling you to track their progress and jump into action at every stage of the recruitment process."
          />
        </p>

        <FormattedMessage
          id="onboardingV2.candidates.intro.text3"
          defaultMessage="Candidate profile information basic fields:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.name"
                  defaultMessage="Candidate name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.email"
                  defaultMessage="Email"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.role"
                  defaultMessage="Role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.country"
                  defaultMessage="Country"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.seniority"
                  defaultMessage="Seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.recruiter"
                  defaultMessage="Dedicated recruiter"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.posting"
                  defaultMessage="Job posting"
                />
              ),
            },
          ]}
        />
      </OnboardingIntroTextWidget>

      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.tips.title"
            defaultMessage="Set up your candidates"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.title"
                defaultMessage="Candidate profile"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.profile.text"
                defaultMessage="Candidate profiles are a centralised hub for all essential information about your applicants. You can review the candidate timeline, send emails, schedule interviews, provide feedback through interview scorecards or add comments and create tasks for other members of your team, all from a single place."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.title"
                defaultMessage="How to add candidates?"
              />
            ),
            icon: 'AddContact',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.add.text"
                defaultMessage="There are two methods for uploading your candidates. For companies with an existing pool of applicants, bulk upload is the most efficient option. During the candidate upload step, click on “Upload candidates” to add them in bulk. If you're just beginning the hiring process and have only a handful of applicants, consider adding them manually, one at a time, by selecting the “Add candidate” button."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.title"
                defaultMessage="Candidate profile permissions"
              />
            ),
            icon: 'Shield',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.permissions.text"
                defaultMessage="To view candidate information, you must be assigned as a dedicated recruiter, be the hiring manager, or have explicit Recruiter or Recruitment Manager access. Selected profiles can be temporarily shared with other employees to review, ensuring everyone is aligned when hiring a top talent."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.title"
                defaultMessage="Sensitive information"
              />
            ),
            icon: 'EyeShow',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.sensitive.text"
                defaultMessage="Certain information within the candidate profile may be sensitive. Fields such as Desired Salary or Right to Work status are accessible only to members of the organisation actively involved in the hiring process of a specific candidate: the Assigned Recruiter and Hiring Manager."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.title"
                defaultMessage="GDPR Compliance"
              />
            ),
            icon: 'LockClosed',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.gdpr.text"
                defaultMessage="You can set up Compliance rules to ensure that your organisation is compliant with latest regulations, no matter where you operate. Anonymise candidates that requested their data to be removed with a single click."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
